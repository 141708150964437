/* Element Styles */
body {
    margin: 0;
    font-family: 'Open Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    max-width: 100vw;
    box-sizing: border-box;
    letter-spacing: 1px;
}

h1{
    font-size: 60px;
    line-height: 80px;
    color: #EEE;
}

h2{
    font-size: 26px;
    line-height: 28px;
    color: #EEE;
}

h3{
    font-size: 24px;
    color: rgb(30, 30, 30);
}

h4{
    font-size: 20px;
}

h5{
    font-size: 18px;   
}

h6{
    margin-bottom: 10px;
    font-weight: bold;
}

ul{
    list-style: disc;
}

li{
    padding-bottom: 3px;
}

/* Navigation Styles */

nav{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100vw;
    padding: 40px;
    box-sizing: border-box;
    background-color:rgb(30, 30, 30);
}

.nav-links{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 80vw;
    font-size: 18px;
}

.div-logo{
    width: calc(705px*0.25);
    height: calc(250px*0.25);
    margin-left: 2vw;
    background-color: white;
    font-weight: lighter;
    font-size: 38px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(30, 30, 30);
    letter-spacing: 0;
    border-radius: 10px;
}

.div-logo:hover{
    transition: ease 0.5s;
    cursor: pointer;
    font-weight: normal;
}

.nav-link{
    color: #FFF;
    font-size: 24px;
    padding: 1.5vh;
}

.nav-link:hover{
    font-weight: bolder;
    transition: ease-in 0.2s;
    cursor: pointer;
}

.selected-nav-link{
    border: 2px solid white;
    border-radius: 10px;
    transition: ease-in 0.2s;
}

.hamburger-icon{
    display: none;
}

.hamburger-links{
    display: none;
}

/* X-page styles */

.page-container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100vw;
}

.dark-text{
    color: rgb(30, 30, 30);
}

.italic{
    font-style: italic;
}

.bolder{
    font-weight: bolder;
}

.colored-text{
    color: rgb(103, 90, 177);
}

.underline-link{
    text-decoration: none;
    color:rgb(30, 30, 30);
    width: fit-content;
    transition: ease-out 0.3s;
}

.light-underline-link{
    color: white;
}

.underline-link:hover{
    text-decoration: underline;
    color: rgb(136, 151, 221);
    cursor: pointer;
}

.page-banner-header{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70px;
    width: 100%;
}

.dark-container{
    width: 100%;
    display: flex;
    background-color: rgb(30, 30, 30)
}

.light-container{
    width: 100%;
    display: flex;
    background-color: rgb(250, 250, 250);
    padding: 20px 0;
    justify-content: center;
}

.centered-narrow{
    width: 60%;
}

.width-100{
    width: 100%;
}

.bottom-page-tagline{
    padding-top: 55px;
}

.d-none{
    display: none;
}

/* Footer Styles */

footer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    padding: 40px;
    box-sizing: border-box;
    background-color:rgb(30, 30, 30);
    color: white;
}

.socials-links{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 30px;
}

.social-img{
    width: 35px;
    height: 35px;
    margin: 0 20px;
}

.github-img{
    background-image: url(./Assets/github.png);
    background-size: cover;
}

.linkedin-img{
    background-image: url(./Assets/linkedin.png);
    background-size: cover;
}

.email-img{
    background-image: url(./Assets/email.png);
    background-size: cover;
}

/* Icon Styles */

.arrow-logo{
    background-image: url(./Assets/arrow.png);
    width: 25px;
    height: 25px;
    background-size: cover;
    margin-left: 5px;
}

.inv-arrow-logo{
    background-image: url(./Assets/inv-arrow.png);
    width: 25px;
    height: 25px;
    background-size: cover;
    margin-left: 5px;
}

/* Home Page Styles */

.home-container{
    height: 73vh;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.profile-pic{
    width: 400px;
    height: 400px;
    background-image: url(./Assets/kt_profile.jpeg);
    background-size: cover;
    border-radius: 20px;
}

.home-portfolio{
    padding: 50px 0 70px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Slideshow */

.slideshow-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}

.slideshow {
    margin: 0 auto;
    overflow: hidden;
    width: calc(80vw*0.32);
    height: calc(50vw*0.32);
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    border-radius: 8px;
    box-sizing: border-box;
    border: 2px solid white;
}

.slideshow:hover{
    cursor: pointer;
    border: 2px solid rgb(136, 151, 221);
    transition: ease-out 0.5s;
}
  
.slideshowSlider {
    white-space: nowrap;
    transition: ease 1000ms;
    width: 100%;
    height: 100%;
    border-radius: 8px;
}
  
.slide {
    display: inline-block;
    width: 100%;
    height: 100%;
    background-size: cover;
}

.slide:hover{
    cursor: pointer;
}

.slideshow-portfolio-text{
    width: calc(80vw*0.32);
    padding: 0;
    text-align: center;
}

/* About styles */

.about-container{
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 73vh;
    padding: 0 0 30px 0;
    box-sizing: border-box;
}

.about-container h2{
    padding: 14px 0 14px 0;
    font-weight: 500;
    line-height: 31px;
    font-size: 24px;
}

.about-skills-container{
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0 0 130px 0;
    border-bottom: 1.5px rgb(175, 175, 175) solid;
}

.skills-box{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    height: fit-content;
    padding: 80px 0 30px 0;
}

.skills-heading{
    width: 100%;
    padding: 30px 0 15px 0;
    text-align: center;
    font-weight: bold;
    box-sizing: border-box;
}

.skills-icon{
    height: 75px;
    width: 75px;
    border-radius: 10%;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    margin: 0 5px;
    background-color: white;
}

.javascript-icon{
    background-image: url(./Assets/skills/javascript.png);
    background-size: cover;
    background-position: center;
}

.html-icon{
    background-image: url(./Assets/skills/html-5.png);
    background-size: cover;
    background-position: center;
}

.css-icon{
    background-image: url(./Assets/skills/css.png);
    background-repeat: no-repeat;
    background-size: 71%;
    background-position: center;
}

.python-icon{
    background-image: url(./Assets/skills/python.png);
    background-size: cover;
    background-position: center;
}

.react-icon{
    background-image: url(./Assets/skills/react.png);
    background-size: cover;
    background-position: center;
}

.bootstrap-icon{
    background-image: url(./Assets/skills/bootstrap.png);
    background-size: cover;
    background-position: center;
}

.nodejs-icon{
    background-image: url(./Assets/skills/nodejs.svg);
    background-repeat: no-repeat;
    background-size: 90%;
    background-position: center;
}

.npm-icon{
    background-image: url(./Assets/skills/npm.png);
    background-repeat: no-repeat;
    background-size: 95%;
    background-position: center;
}

.webpack-icon{
    background-image: url(./Assets/skills/webpack.png);
    background-repeat: no-repeat;
    background-size: 115%;
    background-position: center;
}

.jest-icon{
    background-image: url(./Assets/skills/jest.png);
    background-repeat: no-repeat;
    background-size: 85%;
    background-position: center;
}

.babel-icon{
    background-image: url(./Assets/skills/babel.svg);
    background-repeat: no-repeat;
    background-size: 95%;
    background-position: 100% 50%;
}

.eslint-icon{
    background-image: url(./Assets/skills/eslint.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.prettier-icon{
    background-image: url(./Assets/skills/prettier.png);
    background-size: 100%;
    background-position: center;
}

.git-icon{
    background-color: white;
    background-image: url(./Assets/skills/git.png);
    background-repeat: no-repeat;
    background-size: 90%;
    background-position: center;
}

.github-icon{
    background-color: white;
    background-image: url(./Assets/skills/github.png);
    background-repeat: no-repeat;
    background-size: 90%;
    background-position: center;
    position: relative;
}

.click-icon{
    width: 20px;
    height: 20px;
    background-image: url(./Assets/click.png);
    background-size: contain;
    position: absolute;
    right: -8px;
    bottom: -7px;
}

.click-label{
    text-decoration: none;
    font-size: 10px;
    position: absolute;
    bottom: -25px;
    left: 2px;
    width: 50px;
    color: rgb(30, 30, 30);
    text-align: center;
}

.figma-icon{
    background-image: url(./Assets/skills/figma.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.photoshop-icon{
    background-image: url(./Assets/skills/photoshop.png);
    background-repeat: no-repeat;
    background-size: 110%;
    background-position: center;
}

.vscode-icon{
    background-image: url(./Assets/skills/vscode.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.pycharm-icon{
    background-image: url(./Assets/skills/pycharm.jpeg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.wordpress-icon{
    background-image: url(./Assets/skills/wordpress.png);
    background-repeat: no-repeat;
    background-size: 130%;
    background-position: center;
}

.firebase-icon{
    background-image: url(./Assets/skills/firebase.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.about-personal-wrapper{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    background-color: rgb(30, 30, 30);
    color: white;
}

.about-personal-wrapper h3{
    color: white;
    text-align: center;
}

.about-personal-content{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    padding: 60px;
}

.about-personal-content h2{
    padding: 16px 0;
    line-height: 28px;
    font-size: 22px;
    font-weight: 500;
}

.indent{
    padding: 8px 0!important;
    margin-left: 40px;
}

.about-personal-text{
    width: 50%;
}

.about-pic{
    width: 400px;
    height: 400px;
    background-image: url(./Assets/IMG_8412.jpg);
    background-size: cover;
    background-position: 50% 38%;
    border-radius: 20px;
}

/* Featured & Portfolio Styles*/

.portfolio-items-wrapper, .featured-portfolio-1{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
    justify-content: center;
    padding: 10px 15px;
    margin: 10px 0;
    flex-wrap: wrap;
}

.featured-portfolio{
    border-radius: 8px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.portfolio-pic{
    background-size: cover;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    border-radius: 8px;
}

.featured-portfolio-pic{
    width: calc(80vw*0.52);
    height: calc(60vw*0.52);
    background-image: url(./Assets/screenshots/today-list-2.png);
    box-sizing: border-box;
}

.featured-portfolio-pic-wrapper{
    width: calc(80vw*0.52);
    height: calc(50vw*0.52);
    overflow: scroll;
    border-radius: 8px;
    box-sizing: border-box;
    border: 2px solid white;
}

.main-portfolio-pic-wrapper{
    width: calc(80vw*0.32);
    height: calc(50vw*0.32);  
    overflow: scroll;
}

.main-portfolio-pic:hover, .featured-portfolio-pic-wrapper:hover{
    cursor: pointer;
    border: 2px solid rgb(136, 151, 221);
    transition: ease-out 0.5s;
}

.main-portfolio-pic{
    width: calc(80vw*0.32);
    height: calc(50vw*0.32);  
    box-sizing: border-box;
    border: 2px solid white;
}

.portfolio-text{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.featured-portfolio-text{
    padding: 40px;
    width: 44vw;
    min-height: calc(50vw*0.52);
}

.main-portfolio-text{
    width: calc(80vw*0.32);
    padding: 25px 0;
    text-align: center;
}

.featured-portfolio-subheading{
    font-size: 22px;
    line-height: 24px;
    margin-bottom: 15px;
    height: fit-content;
    min-height: 80px;
    color: rgb(60, 60, 60);
    font-weight: lighter;
    text-align: center;
}

.portfolio-subheading{
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 25px;
    height: fit-content;
    min-height: 70px;
    width: 90%;
    text-align: justify;
    font-weight: lighter;
}

.portfolio-heading{
    margin-bottom: 18px;
    font-size: 26px;
    width: fit-content;
    position: relative;
}

.list-header{
    padding-bottom: 20px;
    font-weight: normal;
}

.portfolio-list{
    display: flex;
    flex-direction: row;
}

.main-portfolio-list{
    width: 100%;
    align-items: center;
    justify-content: center;
}

.play-pause{
    width: 25px;
    height: 25px;
    background-image: url(./Assets/play-pause.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
}

.play-pause:hover{
    cursor: pointer;
    width: 26px;
    height: 26px;
}

.portfolio-icon{
    height: 50px;
    width: 50px;
    border-radius: 10%;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    margin: 0 15px 0 0;
    background-color: white;
}

.portfolio-list .github-icon:hover{
    border: 1px solid rgb(136, 151, 221);
    box-sizing: border-box;
    cursor: pointer;
}

.main-portfolio-text .portfolio-icon{
    width: 35px;
    height: 35px;
    margin: 0 6px;
}

.portfolio-main{
    padding: 60px 0 100px 0;
    border-top: 1.5px rgb(175, 175, 175) solid
}

.portfolio-item{
    margin: 50px 20px 90px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.tic-tac-toe{
    background-image: url(./Assets/screenshots/ttt.png);
    background-size: 122%;
    background-position: 100% 0%;
}

.restaurant{
    background-image: url(./Assets/screenshots/restaurant.png);
    background-size: contain;
}

.bootstrapfashion{
    background-image: url(./Assets/screenshots/bootstrap-fashion.png);
    background-size: contain;
}

.battleship{
    background-image: url(./Assets/screenshots/battleship3.png);
    background-repeat: no-repeat;
    background-position: 50% 0%;
    background-size: 110%;
}

.weather{
    background-image: url(./Assets/screenshots/weather-app-2.png);
    background-size: 100%;
    background-position: 50% 45%;
}

.portfolio-tagline{
    padding-top: 0px;
}

/* Contact Styles */

.contact-container{
    height: calc(100vh - 300px);
    padding: 10px 0;
}

.contact-container a{
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.contact-container h5{
    color: rgb(30, 30, 30);
    font-size: 32px;
    font-weight: bolder;
    margin: 10px 0 18px 8px;
} 

.contact-icon{
    height: 25px;
    width: 25px;
}

.linkedin-dark{
    background: url(./Assets/LI-dark.png) no-repeat;
    background-position: center;
    background-size: contain;
}

.email-dark{
    background: url(./Assets/email-dark.png) no-repeat;
    background-position: center;
    background-size: contain;
}

.twitter-dark{
    background: url(./Assets/twitter-dark.png) no-repeat;
    background-position: center;
    background-size: contain;
}

.github-dark{
    background: url(./Assets/skills/github.png) no-repeat;
    background-position: center;
    background-size: contain;
}

/* MEDIA QUERIES */
/* Website designed with laptop in mind (M-L) */

/* XXL screens (large desktop) */
@media(min-width: 2000px){
    /* XPage */
    h3{
        font-size: 30px;
    }

    h4{
        font-size: 26px;
    }

    .page-banner-header{
        height: 100px;
    }

    /* Nav */
    .nav-link{
        font-size: 32px;
    }

    .nav-link:hover{
        font-size: 30px;
    }

    .nav-links{
        width: 70vw;
    }

    /* Home */
    .home-container{
        height: 75vh;
    }

    .home-container h1{
        padding-bottom: 20px;
        font-size: 74px;
    }

    .col-text-wrapper{
        padding-bottom: 30px;
    }

    /* Featured Portfolio & Portfolio */
    .featured-portfolio-subheading{
        font-size: 28px;
        line-height: 30px;
        height: fit-content;
        margin-bottom: 25px;
    }

    .portfolio-heading{
        font-size: 32px;
    }

    .portfolio-subheading{
        font-size: 26px;
        line-height: 28px;
        height: 100px;
    }

    .portfolio-icon{
        height: 60px;
        width: 60px;
        margin: 0 15px;
    }

    .main-portfolio-text .portfolio-icon{
        width: 50px;
        height: 50px;
        margin: 0 10px;
    }

    /* About */

    .about-container h2{
        padding: 24px 0 24px 0;
        font-size: 28px;
        line-height: 34px;
    }

    .about-container{
        height: 75vh;
        padding-top: 42px;
    }

    .about-personal-content h2{
        font-size: 28px;
        line-height: 32px;
    }

    .indent{
        padding: 12px 0!important;
    }

    .about-pic{
        width: 450px;
        height: 450px;
    }

    /* Footer */

    footer{
        padding: 50px;
    }

    .socials-links{
        margin-bottom: 40px;
    }

    /* Contact */
    .contact-container{
        height: calc(100vh - 340px)
    }
}

/* L - Laptops [designed with this in mind] */
@media(min-width: 1025px) and (max-width: 1999px){

}

/* M - Small laptops/tablets */
@media(min-width: 769px) and (max-width:1024px){
    /* XPage */
    h3{
        font-size: 22px;
    }

    h4{
        font-size: 18px
    }

    .page-banner-header{
        height: 60px;
    }

    /* Nav */
    .nav-link{
        font-size: 20px
    }

    .nav-link:hover{
        font-size: 22px;
    }

    .nav-links{
        width: 60vw;
    }

    /* Home */

    .home-container h1{
        padding-bottom: 20px;
        font-size: 46px;
        line-height: 50px;
    }

    .profile-pic{
        width: 30vw;
        height: 30vw;
    }

    /* Featured Portfolio & Portfolio */
    .featured-portfolio-subheading{
        font-size: 18px;
        line-height: 20px;
        height: fit-content;
        margin-bottom: 15px;
    }

    .portfolio-heading{
        font-size: 24px;
    }

    .portfolio-subheading{
        font-size: 17px;
        line-height: 18px;
        height: fit-content;
        text-align: start;
    }

    .portfolio-icon{
        height: 40px;
        width: 40px;
        margin: 5px 10px;
    }

    .main-portfolio-pic-wrapper, .main-portfolio-pic, .slideshow{
        width: calc(80vw*0.45);
        height: calc(50vw*0.45);
        margin: 0 20px;
    }

    .main-portfolio-text{
        width: calc(80vw*0.45);
    }

    .featured-portfolio-text .portfolio-list{
        flex-wrap: wrap;
        justify-content: center;
        width: 70%;
    }

    .featured-portfolio-text{
        height: fit-content;
    }

    .home-portfolio{
        padding-top: 0;
    }

    /* About */

    .about-container h2{
        padding: 10px 0 10px 0;
        font-size: 19px;
        line-height: 21px;
    }

    .about-container{
        height: 71vh;
        padding-top: 6px;
    }

    .about-skills-container{
        padding: 0 0 80px 0!important;
    }

    .skills-box{
        padding: 20px 0;
    }

    .about-personal-content{
        padding: 70px 30px 50px 30px;
    }

    .about-personal-content h2{
        font-size: 18px;
        line-height: 20px;
    }

    .about-personal-text{
        width: 40%;
        padding-right: 20px 50px 0 0;
    }

    .indent{
        padding: 8px 0!important;
        margin-left: 20px!important;
    }

    .about-pic{
        width: 32vw;
        height: 32vw;
    }

}

/* S - Tablets */
@media(max-width: 768px){

    /* X-Page */
    .centered-narrow{
        width: 75%;
    }

    /* Nav */
    .nav-link{
        font-size: 16px;
        padding: 3px 9px;
    }

    .nav-link:hover{
        font-size: 17px;
    }

    .nav-links{
        width: fit-content
    }

    .selected-nav-link{
        border: 1px solid white;
    }

    /* home */
    .home-container{
        height: fit-content;
        flex-direction: column;
        justify-content: center;
    }

    .home-container h1{
        padding: 5px;
        font-size: 32px;
        line-height: 32px;
        width: 70vw;
    }

    .profile-pic{
        width: 70vw;
        height: 70vw;
        margin: 0 0 40px 0;
    }
    
    .col-text-wrapper{
        padding: 30px 0;
        text-align: center;
    }

    /* Portfolio & featured */

    .featured-portfolio{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .featured-portfolio-text .portfolio-heading{
        margin-top: 25px;
    }

    .featured-portfolio-pic{
        width: calc(80vw*0.90);
        height: calc(60vw*0.90);
    }

    .featured-portfolio-pic-wrapper{
        width: calc(80vw*0.90);
        height: calc(50vw*0.90);
    }

    .featured-portfolio-text{
        height: fit-content;
        width: calc(80vw*0.90);
        padding: 15px 5px;
    }

    .featured-portfolio-subheading{
        height: fit-content;
        margin-bottom: 15px;
    }

    .featured-portfolio-text .portfolio-list{
        flex-wrap: wrap;
        justify-content: center;
        width: 60%;
    }

    .list-header{
        padding: 10px 0;
    }

    .portfolio-icon{
        margin: 15px 15px 0 0;
    }

    .slideshow, .main-portfolio-pic-wrapper, .main-portfolio-pic{
        width: calc(80vw*0.90);
        height: calc(50vw*0.90);
    }

    .main-portfolio-text{
        width: calc(80vw*0.90);
    }

    /* About */

    .about-container{
        padding: 10px 0 40px 0;
        height: fit-content;
    }

    .about-container h2{
        font-size: 19px;
        line-height: 22px;
        padding: 15px 0;
    }

    .skills-box{
        padding: 5px;
        max-width: 75%;
        min-width: 37%;
    }

    .skills-icon{
        height: 65px;
        width: 65px;
        margin: 10px 5px;
    }

    .about-personal-content{
        padding: 0;
    }

    .about-personal-content h2{
        padding: 16px 0;
        line-height: 28px;
        font-size: 19px;
        font-weight: 500;
    }

    .about-personal-text{
        width: 80vw;
    }

    .indent{
        margin-left: 20px;
    }

    .about-pic{
        width: 80vw;
        height: 80vw;
        margin: 16px 0 50px 0;
    }

    .contact-container h5{
        font-size: 20px;
    }

}

/* Hamburger Styles */
@media(max-width: 600px){
    nav{
        flex-wrap: wrap;
    }

    .main-nav-links{
        display: none;
    }

    .hamburger-links{
        position: fixed;
        top: 100px;
        right: 2%;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: rgb(30, 30, 30);
        background-color: rgba(30, 30, 30, 0.9);
        border-radius: 5px;
    }

    .nav-link{
        padding: 25px;
    }

    .selected-nav-link{
        border: none;
        text-decoration: underline!important;
        font-weight: bold;
    }

    .hamburger-icon{
        width: 35px;
        height: 25px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }

    .hamburger-icon:hover{
        cursor: pointer;
        width: 36px;
    }

    .hamburger-layer{
        width: 90%;
        height: 15%;
        background-color: white;
        border-radius: 10%;
    }
}

/* XS - mobiles */
@media(max-width: 480px){

    /* Featured Portfolio / Portfolio */
    .featured-portfolio-text .portfolio-list{
        width: 80%;
    }

    .portfolio-subheading{
        height: fit-content;
    }

    /* Footer */

    footer p{
        text-align: center;
    }

}
